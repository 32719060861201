<div
	class="app-item"
	[class.app-item--disabled]="disabled()"
	[class.app-item--with-reset]="showReset()"
>
	<mat-checkbox
		class="app-item__checkbox"
		[checked]="checked()"
		(change)="update($event.checked)"
		[disabled]="disabled()"
	>
		{{ text() }}
	</mat-checkbox>
	@if (showReset()) {
		<span
			class="text-layout-cadet-gray text-xl leading-none cursor-pointer p-3"
			[class.text-layout-quicksilver]="disabled()"
			[mtxPopoverTriggerFor]="popover"
			mtxPopoverTriggerOn="hover"
		>
			<mat-icon inline class="material-symbols-filled align-middle">info</mat-icon>
		</span>

		<mtx-popover
			#popover="mtxPopover"
			[position]="['below', 'before']"
			[yOffset]="-10"
			[arrowWidth]="30"
			[arrowOffsetX]="30"
		>
			<p class="app-item-tooltip">
				Configuration differs from group policy
				<button class="app-item-tooltip__button" matRipple (click)="reset.emit()">Reset</button>
			</p>
		</mtx-popover>
	}
</div>
